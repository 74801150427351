import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faMinus, faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { db } from './firebase';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0px;
`;

const FormGroup = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  gap: 5px;
  flex-direction: column;
  width: 100%;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  padding: 9px 18px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 50px;
  width: -webkit-fill-available;
  margin-bottom: 15px;
`;

const Textarea = styled.textarea`
  padding: 12px 18px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  width: -webkit-fill-available;
  margin-bottom: 15px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007f7f;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
`;

const Button2 = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  color: #007f7f;
  border: solid #007f7f 1px;
  border-radius: 50px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;
`;

const BGWrapper = styled.div`
  max-width: 100%;
`;

const CurrencySelect = styled.select`
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  width: 150px;
  margin-bottom: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px; /* Add some right margin */
  font-size: 18px; /* Adjust the size if needed */
  color: #007f7f; /* Adjust the icon color to match the button color */
`;

const StyledSelect = styled.select`
  padding: 9px 18px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 50px;
  width: -webkit-fill-available;
  margin-bottom: 15px;
  background-color: #fff; /* You might need this if your default styles are different */
  color: #333; /* Adjust text color if necessary */
`;

const SelectWrapper = styled.div`
  position: relative;
  width: -webkit-fill-available;

  &::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 38%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #007f7f; /* Change color if needed */
    pointer-events: none; // This ensures that clicks are passed through to the select element
  }
`;


const InvoiceForm = () => {
  const [title, setTitle] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('CZK'); // Default currency is CZK
  const [recipient, setRecipient] = useState('');
  const [address, setAddress] = useState(''); // Added address state
  const [ic, setIc] = useState(''); // Added ic state
  const [dic, setDic] = useState(''); // Added dic state
  const [finalPrice, setFinalPrice] = useState('');
  const [manualInvoiceNumber, setManualInvoiceNumber] = useState('');
  const [lastInvoiceNumber, setLastInvoiceNumber] = useState(0);
  const [formRows, setFormRows] = useState([{ id: 1 }]);
  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().substr(0, 10)); // New state for invoice date
  const [validityDays, setValidityDays] = useState(14);
  const [exchangeRate, setExchangeRate] = useState(22.417); // default rate
  const [recipients, setRecipients] = useState([]);

  

  useEffect(() => {
    // Fetching recipients
    const unsubscribeRecipients = db.collection('recipients').onSnapshot((snapshot) => {
      const recipientsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecipients(recipientsData);
    });

    // Fetch the last invoice number from the database
    const unsubscribe = db
      .collection('invoices')
      .orderBy('invoiceNumber', 'desc')
      .limit(1)
      .onSnapshot((snapshot) => {
        const lastInvoice = snapshot.docs[0];
        if (lastInvoice) {
          setLastInvoiceNumber(lastInvoice.data().invoiceNumber);
        }
      });

    return () => {
      unsubscribe();
      unsubscribeRecipients();
    };
  }, []);

  const handleTitleChange = (e, id) => {
    const updatedFormRows = formRows.map((row) => {
      if (row.id === id) {
        return { ...row, title: e.target.value };
      }
      return row;
    });
    setTitle(e.target.value);
    setFormRows(updatedFormRows);
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleRecipientChange = (e, id) => {
    const updatedFormRows = formRows.map((row) => {
      if (row.id === id) {
        return { ...row, recipient: e.target.value };
      }
      return row;
    });
    setRecipient(e.target.value);
    setFormRows(updatedFormRows);
  };

  const handleFinalPriceChange = (e, id) => {
    const value = e.target.value;
    const formattedValue = value.replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    const updatedFormRows = formRows.map((row) => {
      if (row.id === id) {
        return { ...row, finalPrice: formattedValue };
      }
      return row;
    });

    setFinalPrice(formattedValue);
    setFormRows(updatedFormRows);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleIcChange = (e) => {
    setIc(e.target.value);
  };

  const handleDicChange = (e) => {
    setDic(e.target.value);
  };

  const handleManualInvoiceNumberChange = (e) => {
    setManualInvoiceNumber(e.target.value);
  };

  const handleAddRow = () => {
    const newId = formRows.length + 1;
    const updatedRows = [...formRows, { id: newId }];
    setFormRows(updatedRows);
  };

  const handleRemoveLastRow = () => {
    if (formRows.length > 1) {
      const updatedRows = formRows.slice(0, -1);
      setFormRows(updatedRows);
    }
  };

  // Handler to prefill recipient data
  const prefillRecipientData = (recipientId) => {
    const selectedRecipient = recipients.find((r) => r.id === recipientId);
    if (selectedRecipient) {
      setRecipient(selectedRecipient.recipient);
      setIc(selectedRecipient.ic);
      setDic(selectedRecipient.dic);
      setAddress(selectedRecipient.address);
    }
  };

  const handleValidityDaysChange = (e) => {
    setValidityDays(parseInt(e.target.value));
  };

  const handleExchangeRateChange = (e) => {
    setExchangeRate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    let newInvoiceNumber;
    if (manualInvoiceNumber) {
      newInvoiceNumber = parseInt(manualInvoiceNumber, 10);
    } else {
      newInvoiceNumber = lastInvoiceNumber + 1;
    }
  
    const newInvoice = {
      recipient,
      address,
      ic,
      dic,
      items: formRows.map((row) => ({
        title: row.title || '',
        finalPrice: row.finalPrice || '',
      })),
      invoiceNumber: newInvoiceNumber,
      invoiceDate,
      validityDays,
      currency: selectedCurrency,
      exchangeRate: selectedCurrency === 'USD' ? parseFloat(exchangeRate) : null,
    };
  
    db.collection('invoices')
      .add(newInvoice)
      .then((docRef) => {
        console.log('Invoice stored with ID:', docRef.id);
        setTitle('');
        setRecipient('');
        setAddress('');
        setIc('');
        setDic('');
        setFinalPrice('');
        setManualInvoiceNumber('');
        setFormRows([{ id: 1 }]);
        setSelectedCurrency('CZK'); // Reset the selected currency to CZK
        const inputs = document.querySelectorAll('input[type="text"]');
        inputs.forEach((input) => (input.value = ''));
      })
      .catch((error) => {
        console.error('Error storing invoice:', error);
      });
  };
  

  return (
    <>
    <h2><FontAwesomeIcon icon={faPlus} />&nbsp;Vytvořit fakturu</h2>
    <Form onSubmit={handleSubmit}>
      <CurrencySelect value={selectedCurrency} onChange={handleCurrencyChange}>
        <option value="CZK">CZK</option>
        <option value="EUR">EUR</option>
        <option value="USD">USD</option>
      </CurrencySelect>
      
      {/* 2. Conditional Input Field for Exchange Rate */}
      {selectedCurrency === 'USD' && (
        <FormGroup style={{display:'Flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',gap:'20px'}}>
          <Label><strong>Exchange Rate (USD to CZK):</strong></Label>
          <Input
            type="number"
            value={exchangeRate}
            onChange={handleExchangeRateChange}
            placeholder="Enter exchange rate"
            required
            style={{width:100,margin:0}}
          />
          <a target='_blank' href="https://www.cnb.cz/cs/financni-trhy/devizovy-trh/kurzy-devizoveho-trhu/kurzy-devizoveho-trhu/">Aktuální kurz</a>
        </FormGroup>
        
      )}
      <h3><Icon icon={faUser} /> Odběratel</h3>
      <FormGroup>
      <SelectWrapper>
    <StyledSelect onChange={(e) => prefillRecipientData(e.target.value)} defaultValue="">
      <option value="" disabled>Vybrat odběratele</option>
      {recipients.map((recipient) => (
        <option key={recipient.id} value={recipient.id}>
          {recipient.recipient}
        </option>
      ))}
    </StyledSelect>
  </SelectWrapper>
      </FormGroup>
      <FormRow>
      <FormGroup>
        <Input
          type="text"
          value={recipient}
          onChange={handleRecipientChange}
          placeholder="Odběratel"
          required
        />
      </FormGroup>
        <FormGroup>
          <Input
            type="text"
            value={ic}
            onChange={handleIcChange}
            placeholder="IČ"
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="text"
            value={dic}
            onChange={handleDicChange}
            placeholder="DIČ"
          />
        </FormGroup>
      </FormRow>
      <FormGroup>
        <Textarea
          value={address}
          onChange={handleAddressChange}
          placeholder="Adresa"
        />
      </FormGroup>
      <FormRow>
      <FormGroup>
        <Label><strong>Datum faktury:</strong></Label>
        <Input
          type="date"
          value={invoiceDate}
          onChange={(e) => setInvoiceDate(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label><strong>Platnost faktury (dny):</strong></Label>
        <Input
          type="number"
          value={validityDays}
          onChange={handleValidityDaysChange}
          required
        />
      </FormGroup>
      
      <FormGroup>
        <Label><strong>Číslo faktury:</strong></Label>
        <Input
          type="text"
          value={manualInvoiceNumber}
          onChange={handleManualInvoiceNumberChange}
          placeholder="Volitelné číslo faktury"
        />
      </FormGroup>
      </FormRow>
      <h3><Icon icon={faShoppingCart} /> Položky</h3>
      <BGWrapper>
        {formRows.map((row) => (
          <FormRow key={row.id}>
            {row.id === 1 && (
              <>
                <FormGroup>
                  <Input
                    type="text"
                    value={row.title}
                    onChange={(e) => handleTitleChange(e, row.id)}
                    placeholder="Název položky"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    value={row.finalPrice}
                    onChange={(e) => handleFinalPriceChange(e, row.id)}
                    placeholder={`Částka v ${selectedCurrency}`}
                    required
                  />
                </FormGroup>
              </>
            )}
            {row.id !== 1 && (
              <>
                <Input
                  type="text"
                  value={row.title}
                  onChange={(e) => handleTitleChange(e, row.id)}
                  placeholder="Název položky"
                  required
                />
                <Input
                  type="text"
                  value={row.finalPrice}
                  onChange={(e) => handleFinalPriceChange(e, row.id)}
                  placeholder={`Částka v ${selectedCurrency}`}
                  required
                />
              </>
            )}
          </FormRow>
        ))}
        <FormRow style={{ width: '100%' }}>
          <Button2 type="button" onClick={handleAddRow}>
            <FontAwesomeIcon icon={faPlus} />
          </Button2>
          <Button2 type="button" onClick={handleRemoveLastRow}>
            <FontAwesomeIcon icon={faMinus} />
          </Button2>
        </FormRow>
      </BGWrapper>
      
      <Button type="submit">Vytvořit fakturu</Button>
    </Form>
    </>
  );
};

export default InvoiceForm;
