import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { pdf } from '@react-pdf/renderer';
import { faDownload, faTrash, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThreeDots } from 'react-loader-spinner';
import { db } from './firebase';
import InvoicePDF from './InvoicePDF'; // Czech Invoice with QR code support
import InvoicePDFEnglish from './InvoicePDFEnglish'; // English Invoice without QR code
import axios from 'axios';

const List = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
  gap: 10px;
  color: ${(props) => (props.paid ? '#55a630' : '#980409')};

  &:last-child {
    border-bottom: none;
  }
`;

const ItemText = styled.span`
  flex: 1;
  margin-right: 10px;
`;

const Button = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007f7f;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-left: 10px;
`;

const formatCurrency = (amount) => {
  const num = parseFloat(amount);
  if (isNaN(num)) {
    return 'Invalid Price';
  }
  return num.toLocaleString('cs-CZ', { minimumFractionDigits: 0 }) + ' Kč';
};

// Truncate the title to 4 words if it's longer
const truncateTitle = (title) => {
  const words = title.split(' ');
  if (words.length > 7) {
    return words.slice(0, 7).join(' ') + '...';
  }
  return title;
};

const InvoiceList = () => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = db.collection('invoices').onSnapshot((snapshot) => {
      const invoices = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      invoices.sort((a, b) => parseInt(b.invoiceNumber) - parseInt(a.invoiceNumber));

      setInvoiceList(invoices);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const generateQRCode = async (invoice) => {
    const totalAmount = calculateTotalSum(invoice.items).toFixed(2);
    const variableSymbol = invoice.invoiceNumber || '';
    const message = ''; // Customizable message for recipient

    const url = `http://api.paylibo.com/paylibo/generator/czech/image?accountNumber=4375843053&bankCode=0800&amount=${totalAmount}&currency=CZK&vs=${variableSymbol}&message=${encodeURIComponent(message)}&compress=true&branding=true&size=300`;

    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const qrCodeUrl = URL.createObjectURL(response.data);
      return qrCodeUrl; // Returns the QR code URL as a blob
    } catch (error) {
      console.error('Error generating QR code from QR-platba.cz:', error);
      return null;
    }
  };

  const handleDownload = async (invoice) => {
    let qrCodeDataUrl = '';

    // Only generate the QR code for invoices in CZK
    if (invoice.currency === 'CZK') {
      qrCodeDataUrl = await generateQRCode(invoice);
    }

    // Generate the PDF as a blob
    const pdfBlob = await pdf(<InvoicePDF invoice={invoice} qrCode={qrCodeDataUrl} />).toBlob();

    // Create an object URL for the blob
    const url = URL.createObjectURL(pdfBlob);

    // Create a link element to download the file with the desired name
    const link = document.createElement('a');
    link.href = url;
    link.download = `Faktura-${invoice.invoiceNumber}.pdf`; // Set the desired filename
    link.click();

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url);
  };

  const handleEnglishDownload = async (invoice) => {
    const pdfBlob = await pdf(<InvoicePDFEnglish invoice={invoice} />).toBlob();

    // Create an object URL for the blob
    const url = URL.createObjectURL(pdfBlob);

    // Create a link element to download the file with the desired name
    const link = document.createElement('a');
    link.href = url;
    link.download = `Invoice-${invoice.invoiceNumber}.pdf`; // Set the desired filename
    link.click();

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(url);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}. ${month}. ${year}`;
  };

  const handleDelete = (invoiceId) => {
    const confirmed = window.confirm('Opravdu chcete odstranit tuto fakturu?');
    if (confirmed) {
      db.collection('invoices')
        .doc(invoiceId)
        .delete()
        .then(() => {
          console.log('Invoice deleted with ID:', invoiceId);
        })
        .catch((error) => {
          console.error('Error deleting invoice:', error);
        });
    }
  };

  const calculateTotalSum = (items) => {
    return items.reduce((sum, item) => sum + parseInt(item.finalPrice.replace(/\s+/g, '')), 0);
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'CZK':
        return 'Kč';
      case 'EUR':
        return '€';
      case 'USD':
        return '$';
      default:
        return 'Kč';
    }
  };

  const togglePaidStatus = (invoice) => {
    const updatedStatus = !invoice.paid;
    db.collection('invoices')
      .doc(invoice.id)
      .update({ paid: updatedStatus })
      .then(() => {
        setInvoiceList((prevList) =>
          prevList.map((inv) =>
            inv.id === invoice.id ? { ...inv, paid: updatedStatus } : inv
          )
        );
      })
      .catch((error) => {
        console.error('Error updating paid status:', error);
      });
  };

  return (
    <>
      <h2><FontAwesomeIcon icon={faFileAlt} />&nbsp;&nbsp;Faktury</h2>
      {loading ? (
        <ThreeDots color="#007f7f" height={50} width={50} />
      ) : (
        <List>
          {invoiceList.map((invoice) => (
            <ListItem key={invoice.id} paid={invoice.paid}>
              <ItemText onClick={() => togglePaidStatus(invoice)} style={{ cursor: 'pointer', fontSize: '1.5em', maxWidth: 50 }}>
                {invoice.paid ? '💰' : '🫴'}
              </ItemText>
              <ItemText style={{ maxWidth: 75 }}>{invoice.invoiceNumber}</ItemText>
              <ItemText style={{ maxWidth: 100 }}>{formatDate(invoice.invoiceDate)}</ItemText>
              <ItemText style={{ maxWidth: 350 }}>{invoice.recipient}</ItemText>
              
              {/* Display truncated first product title */}
              <ItemText style={{ maxWidth: 200, fontStyle: 'italic' }}>
                {invoice.items && invoice.items.length > 0 ? truncateTitle(invoice.items[0].title) : 'N/A'}
              </ItemText>
              
              <ItemText style={{ maxWidth: 150, marginRight: 'auto' }}>
                {calculateTotalSum(invoice.items)} {getCurrencySymbol(invoice.currency)}
              </ItemText>
              <div>
                <Button onClick={() => handleDownload(invoice)}>
                  <FontAwesomeIcon icon={faDownload} />
                </Button>
                <Button onClick={() => handleEnglishDownload(invoice)}>
                  <FontAwesomeIcon icon={faDownload} /> EN
                </Button>
                <Button onClick={() => handleDelete(invoice.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default InvoiceList;
