import React from 'react';
import styled, { keyframes } from 'styled-components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InvoiceForm from './InvoiceForm';
import InvoiceList from './InvoiceList';
import Sidebar from './Sidebar';
import NavBar from './NavBar';
import SettingsComponent from './SettingsComponent';
import Recipients from './Recipients';
import WorkTracker from './WorkTracker';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

const BlockWrapper = styled.div`
  /*
  padding: 30px;
  background-color: #e6f4f4;
  border: 1px solid #ccc;
  border-radius: 20px
  */
`;

const DownloadButtonPage = () => {
  return (
    <Router>
      <MainWrapper>
        <Sidebar />
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '40px',
            flexDirection: 'column',
          }}
        >
          <NavBar></NavBar>
          <BlockWrapper>
            <Routes>
              <Route path="/" element={<InvoiceForm />} />
              <Route path="/vytvorit" element={<InvoiceForm />} />
              <Route path="/faktury" element={<InvoiceList />} />
              <Route path="/nastaveni" element={<SettingsComponent />} />
              <Route path="/odberatele" element={<Recipients />} />
              <Route path="/work-tracker" element={<WorkTracker />} /> 
            </Routes>
          </BlockWrapper>
        </div>
      </MainWrapper>
    </Router>
  );
};

export default DownloadButtonPage;
