import React, { useEffect } from 'react';
import { Page, Document, StyleSheet, Text, View, Font, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: 30,
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  number: {
    fontSize: 14,
    marginBottom: 10,
  },
  tableContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    borderRadius: 2,
    width: '100%',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderColor: '#000',
    padding: 10,
  },
  tableHeaderCell: {
    flex: 1,
    fontWeight: 'bold',
  },
  tableCell: {
    flex: 1,
  },
  classicRow: {
    flexDirection: 'row',
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold',
  },
  totalSumRow: {
    flexDirection: 'row',
    borderBottomWidth: 0,
    borderTopWidth: 0.5,
    borderColor: '#000',
    padding: 10,
    fontWeight: 'bold',
  },
  bigLightText: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 25,
    color: 'lightgrey',
  },
  paticka: {
    marginTop: 'auto',
    borderTop: 'solid black 1px',
    fontSize: 9,
  },
  qrCode: {
    marginTop: 20,
    width: 125,  // Adjusted to 125px
    height: 125, // Adjusted to 125px
  },
});

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 'light',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 'bold',
    },
  ],
});

const InvoicePDF = ({ invoice, qrCode }) => {
  const pdfFileName = `Faktura-${invoice.invoiceNumber}.pdf`; // Dynamic document name

  const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    if (isNaN(num)) {
      return 'Invalid Price';
    }
    const currencySymbol = invoice.currency.toLowerCase() === 'czk' ? 'Kč' : invoice.currency;
    return num.toLocaleString('cs-CZ', { minimumFractionDigits: 0 }) + ` ${currencySymbol}`;
  };

  const calculateTotalSum = () => {
    return invoice.items.reduce((sum, item) => sum + parseInt(item.finalPrice.replace(/\s+/g, '')), 0);
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}. ${month}. ${year}`;
  };

  const calculateValidityDate = () => {
    const invoiceDate = new Date(invoice.invoiceDate);
    const validityDays = parseInt(invoice.validityDays, 10);
    const validityDate = new Date(invoiceDate.getTime() + validityDays * 24 * 60 * 60 * 1000);
    return formatDate(validityDate.toISOString().slice(0, 10));
  };

  const currencySymbol = invoice.currency.toLowerCase() === 'czk' ? 'Kč' : invoice.currency;

  // Log QR code URL for debugging purposes
  useEffect(() => {
    if (qrCode) {
      console.log('QR Code URL:', qrCode);
    }
  }, [qrCode]);

  return (
    <Document title={pdfFileName} fileName={pdfFileName}> {/* Dynamic document name here */}
      <Page size="A4" style={styles.page}>
        <Text>Faktura</Text>
        <Text style={styles.boldText}>{`${invoice.invoiceNumber}`}</Text>
        <Text style={{ marginBottom: 10 }}>{formatDate(invoice.invoiceDate)}</Text>
        <View style={styles.classicRow}>
          <View style={[styles.tableContainer, { marginRight: 10 }]}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.boldText}>DODAVATEL</Text>
                <Text style={{ marginBottom: 10, fontWeight: 'bold' }}>Richard Mikeš</Text>
                <Text>Vrchlického 1986</Text>
                <Text>272 01 Kladno</Text>
                <Text style={{ marginBottom: 10 }}>Česká Republika</Text>
                <Text style={{ marginBottom: 10 }}>IČ: 09634975</Text>
                <Text>Email: webdesign@richardmikes.cz</Text>
                <Text>Tel: 605781725</Text>
                <Text>Web: www.richardmikes.cz</Text>
              </View>
            </View>
            <View style={[styles.tableRow, { borderBottomWidth: 0 }]}>
              <View style={styles.tableCell}>
                <Text>Peněžní ústav: Česká spořitelna</Text>
                <Text>Číslo účtu: 4375843053/0800</Text>
                <Text>IBAN: CZ2308000000004375843053</Text>
                <Text>SWIFT: GIBACZPX</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableContainer}>
            <View style={[styles.tableRow, { borderBottomWidth: 0 }]}>
              <View style={styles.tableCell}>
                <Text style={{ fontWeight: 'bold' }}>ODBĚRATEL</Text>
                <Text style={{ marginBottom: 10, fontWeight: 'bold' }}>{invoice.recipient}</Text>
                {invoice.address.split('\n').map((line, index) => (
                  <Text key={index} style={{ marginBottom: 0 }}>
                    {line}
                  </Text>
                ))}
                {invoice.ic && <Text style={{ marginTop: 10 }}>{`IČ: ${invoice.ic}`}</Text>}
                {invoice.dic && <Text>{`DIČ: ${invoice.dic}`}</Text>}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={[styles.tableRow, { borderBottomWidth: 0 }]}>
            <View style={styles.tableCell}>
              <Text>Datum vystavení:</Text>
              <Text>{formatDate(invoice.invoiceDate)}</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>Datum splatnosti:</Text>
              <Text style={styles.boldText}>{calculateValidityDate()}</Text>
            </View>
            <View style={[styles.tableCell, { maxWidth: 80 }]}>
              <Text>VS:</Text>
              <Text style={styles.boldText}>{invoice.invoiceNumber}</Text>
            </View>
            <View style={[styles.tableCell, { maxWidth: 80 }]}>
              <Text>KS:</Text>
              <Text>0308</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>Forma úhrady:</Text>
              <Text style={styles.boldText}>Převodním příkazem</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.tableRow}>
            <Text style={styles.tableHeaderCell}>Název</Text>
            <Text style={styles.tableHeaderCell}>Částka</Text>
          </View>
          {invoice.items &&
            invoice.items.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCell}>{item.title}</Text>
                <Text style={styles.tableCell}>{item.finalPrice} {currencySymbol}</Text>
              </View>
            ))}
          <View style={styles.totalSumRow}>
            <Text style={styles.tableCell}>Celkem</Text>
            <Text style={styles.tableCell}>{formatCurrency(calculateTotalSum())}</Text>
          </View>
        </View>

        {/* QR Code */}
        {qrCode && <Image style={styles.qrCode} src={qrCode} />}

        <View><Text style={styles.bigLightText}>Nejsme plátci DPH</Text></View>
        <View style={styles.paticka}>
          <Text>Richard Mikeš</Text>
          <Text>Bankovní spojení: Česká spořitelna, č. ú. 4375843053/0800</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
