import React, { useEffect, useState } from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import { db } from './firebase';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SettingsComponent = () => {
  const [chartData, setChartData] = useState({
    labels: [], // Months
    datasets: [
      {
        label: 'Monthly Earnings',
        data: [], // Earnings
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
        borderColor: 'rgba(0, 123, 255, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = db.collection('invoices');
      const snapshot = await response.get();
      const invoices = snapshot.docs.map(doc => doc.data());
  
      let minYear = new Date().getFullYear(); // Start with the current year
      let minMonth = 11; // Start with the last month
      const usdToCzkRate = 23.15; // Conversion rate from USD to CZK
      const earnings = {}; // Object to hold earnings per month
  
      // Process the data to calculate monthly earnings and find the earliest date
      invoices.forEach(invoice => {
        const dateParts = invoice.invoiceDate.split('-');
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
  
        // Update minYear and minMonth if this invoice is earlier
        if (year < minYear || (year === minYear && month < minMonth)) {
          minYear = year;
          minMonth = month;
        }
  
        invoice.items.forEach(item => {
          const price = parseFloat(item.finalPrice.replace(/\s/g, ''));
          const finalPrice = invoice.currency === 'USD' ? price * usdToCzkRate : price;
          const key = `${year}-${month}`;
          earnings[key] = (earnings[key] || 0) + finalPrice;
        });
      });
  
      // Generate labels and data for all months from the first invoice to the current date
      const labels = [];
      const earningsData = [];
      const currentDate = new Date();
      for (let year = minYear; year <= currentDate.getFullYear(); year++) {
        const startMonth = year === minYear ? minMonth : 0;
        const endMonth = year === currentDate.getFullYear() ? currentDate.getMonth() : 11;
        for (let month = startMonth; month <= endMonth; month++) {
          const key = `${year}-${month}`;
          labels.push(new Date(year, month).toLocaleString('default', { month: 'long', year: 'numeric' }));
          earningsData.push(earnings[key] || 0);
        }
      }
  
      setChartData({
        labels: labels,
        datasets: [
          {
            ...chartData.datasets[0],
            data: earningsData,
          },
        ],
      });
    };
  
    fetchData();
  }, []);  

  return (
    <div>
      <p>Příjmy</p>
      <LineChart data={chartData} options={{ 
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }} />
    </div>
  );
};

export default SettingsComponent;
