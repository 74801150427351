import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPlus, faUsers, faCog, faStopwatch } from '@fortawesome/free-solid-svg-icons'; // Import faStopwatch for Work Tracker

const SidebarContainer = styled.div`
  width: 220px;
  height: auto;
  background-color: #e6f4f4;
  border-right: 1px solid #ccc;
  padding: 20px 20px 20px 30px;
  min-height: 100vh;

  @media (max-width:992px) {
    width: 30px;
    padding: 20px 6px 20px 16px;
    display: flex;
    flex-direction: column;
    align-items:center;
  }
`;

const SidebarHeading = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    display: none;
  }
`;

const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  margin-bottom: 20px;
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const SidebarLinkText = styled.span`
  @media (max-width: 992px) {
    display: none;
  }
`;

const Icon = styled.span`
  margin-right: 10px;
`;

const Sidebar = () => {
  return (
    <SidebarContainer>
      <SidebarHeading>Menu</SidebarHeading>
      <SidebarLink to="/faktury">
        <Icon>
          <FontAwesomeIcon icon={faFileAlt} />
        </Icon>
        <SidebarLinkText>Faktury</SidebarLinkText>
      </SidebarLink>
      <SidebarLink to="/vytvorit">
        <Icon>
          <FontAwesomeIcon icon={faPlus} />
        </Icon>
        <SidebarLinkText>Vytvořit fakturu</SidebarLinkText>
      </SidebarLink>
      <SidebarLink to="/odberatele">
        <Icon>
          <FontAwesomeIcon icon={faUsers} />
        </Icon>
        <SidebarLinkText>Odběratelé</SidebarLinkText>
      </SidebarLink>
      <SidebarLink to="/nastaveni">
        <Icon>
          <FontAwesomeIcon icon={faCog} />
        </Icon>
        <SidebarLinkText>Nastavení</SidebarLinkText>
      </SidebarLink>
      <SidebarLink to="/work-tracker">
        <Icon>
          <FontAwesomeIcon icon={faStopwatch} />
        </Icon>
        <SidebarLinkText>Work Tracker</SidebarLinkText>
      </SidebarLink>
    </SidebarContainer>
  );
};

export default Sidebar;
