import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { db } from './firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';

// Styled components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const ProjectList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ProjectItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
`;

const ProjectName = styled.span`
  font-size: 18px;
  flex: 1;
`;

const TimeDisplay = styled.span`
  margin-left: 10px;
  font-weight: bold;
`;

const TimerButton = styled.button`
  background-color: #007f7f;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #005f5f;
  }
`;

const EditButton = styled.button`
  background-color: #ffcc00;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #e6b800;
  }
`;

const DeleteButton = styled.button`
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #e60000;
  }
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
`;

const AddButton = styled.button`
  background-color: #007f7f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #005f5f;
  }
`;

const WorkTracker = () => {
  const [projects, setProjects] = useState([]);
  const [newProjectName, setNewProjectName] = useState('');
  const [timer, setTimer] = useState({});
  const [editingProject, setEditingProject] = useState(null);
  const [editedTime, setEditedTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  // Načíst projekty z Firebase
  useEffect(() => {
    const unsubscribe = db.collection('projects').onSnapshot(snapshot => {
      const fetchedProjects = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProjects(fetchedProjects);
    });
    return () => unsubscribe();
  }, []);

  // Přidat nový projekt
  const handleAddProject = async () => {
    if (newProjectName.trim() === '') return;
    await db.collection('projects').add({
      name: newProjectName,
      timeSpent: 0, // Počáteční čas strávený je 0
    });
    setNewProjectName('');
  };

  // Spustit/zastavit časovač pro projekt
  const handleTimerToggle = (project) => {
    if (timer[project.id]) {
      // Zastavit časovač
      clearInterval(timer[project.id].intervalId);
      const timeDiff = Math.floor((Date.now() - timer[project.id].startTime) / 1000);
      const updatedTime = project.timeSpent + timeDiff;

      // Aktualizovat čas strávený ve Firebase
      db.collection('projects').doc(project.id).update({
        timeSpent: updatedTime,
      });

      setTimer(prev => {
        const newTimer = { ...prev };
        delete newTimer[project.id];
        return newTimer;
      });
    } else {
      // Spustit časovač
      const startTime = Date.now();
      const intervalId = setInterval(() => {
        setProjects(prevProjects => {
          return prevProjects.map(p => {
            if (p.id === project.id) {
              const timeDiff = Math.floor((Date.now() - startTime) / 1000);
              return { ...p, liveTime: timeDiff }; // Použít liveTime pro zobrazení
            }
            return p;
          });
        });
      }, 1000);

      setTimer(prev => ({
        ...prev,
        [project.id]: { startTime, intervalId },
      }));
    }
  };

  // Upravovat strávený čas (hodiny, minuty, sekundy)
  const handleEditTime = (project) => {
    const hours = Math.floor(project.timeSpent / 3600);
    const minutes = Math.floor((project.timeSpent % 3600) / 60);
    const seconds = project.timeSpent % 60;
    setEditingProject(project.id);
    setEditedTime({ hours, minutes, seconds });
  };

  // Uložit upravený čas
  const handleSaveTime = async (project) => {
    const updatedTime =
      parseInt(editedTime.hours, 10) * 3600 +
      parseInt(editedTime.minutes, 10) * 60 +
      parseInt(editedTime.seconds, 10);

    if (!isNaN(updatedTime)) {
      await db.collection('projects').doc(project.id).update({
        timeSpent: updatedTime,
      });
    }
    setEditingProject(null);
  };

  // Smazat projekt
  const handleDeleteProject = async (projectId) => {
    if (window.confirm('Opravdu chcete smazat tento projekt?')) {
      await db.collection('projects').doc(projectId).delete();
    }
  };

  // Formátovat čas (sekundy) na HH:mm:ss
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <Container>
      <Title>Work Tracker</Title>

      <Input
        type="text"
        value={newProjectName}
        onChange={(e) => setNewProjectName(e.target.value)}
        placeholder="Zadejte název projektu"
      />
      <AddButton onClick={handleAddProject}>Přidat Projekt</AddButton>

      <ProjectList>
        {projects.map(project => (
          <ProjectItem key={project.id}>
            <ProjectName>{project.name}</ProjectName>

            {editingProject === project.id ? (
              <>
                <Input
                  type="number"
                  value={editedTime.hours}
                  onChange={(e) => setEditedTime({ ...editedTime, hours: e.target.value })}
                  placeholder="Hodiny"
                />
                <Input
                  type="number"
                  value={editedTime.minutes}
                  onChange={(e) => setEditedTime({ ...editedTime, minutes: e.target.value })}
                  placeholder="Minuty"
                />
                <Input
                  type="number"
                  value={editedTime.seconds}
                  onChange={(e) => setEditedTime({ ...editedTime, seconds: e.target.value })}
                  placeholder="Sekundy"
                />
                <EditButton onClick={() => handleSaveTime(project)}>
                  <FontAwesomeIcon icon={faSave} />
                </EditButton>
              </>
            ) : (
              <>
                <TimeDisplay>
                  {formatTime(project.liveTime ? project.timeSpent + project.liveTime : project.timeSpent)}
                </TimeDisplay>
                <TimerButton onClick={() => handleTimerToggle(project)}>
                  <FontAwesomeIcon icon={timer[project.id] ? faPause : faPlay} />
                </TimerButton>
                <EditButton onClick={() => handleEditTime(project)}>
                  <FontAwesomeIcon icon={faEdit} />
                </EditButton>
                <DeleteButton onClick={() => handleDeleteProject(project.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </DeleteButton>
              </>
            )}
          </ProjectItem>
        ))}
      </ProjectList>
    </Container>
  );
};

export default WorkTracker;
