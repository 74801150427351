import React from 'react';
import ReactDOM from 'react-dom';
import { Font } from '@react-pdf/renderer';
import App from './App';
import './index.css'; // Import the index.css file

// Register the custom font
Font.register({
  family: 'Inter', // Use the name of your custom font
  src: '/fonts/Inter-Regular.ttf', // Replace with the path to your custom font file
});

ReactDOM.render(<App />, document.getElementById('root'));
