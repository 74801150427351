import React, { useState, useEffect } from 'react'; // Add useEffect here
import styled from 'styled-components';
import { db } from './firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 50px;
`;

const FormGroup = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  gap: 5px;
  flex-direction: column;
  width: 100%;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const Input = styled.input`
  padding: 9px 18px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 50px;
  width: -webkit-fill-available;
  margin-bottom: 15px;
`;

const Textarea = styled.textarea`
  padding: 12px 18px;
  font-size: 14px;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  width: -webkit-fill-available;
  margin-bottom: 15px;
`;

const Button = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007f7f;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius:24px;
`;

const TableHeader = styled.thead`
  background-color: #e6f4f4;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  background-color: #aed4d4;
  color: black;
  font-weight: 400;
`;


const Recipients = () => {
  const [recipient, setRecipient] = useState('');
  const [ic, setIc] = useState('');
  const [dic, setDic] = useState('');
  const [address, setAddress] = useState('');
  const [recipientsList, setRecipientsList] = useState([]); // New state for storing recipients

  useEffect(() => {
    const unsubscribe = db.collection('recipients').onSnapshot((snapshot) => {
      const recipientsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecipientsList(recipientsData);
    });

    return () => unsubscribe(); // Detach listener on unmount
  }, []);


  const handleRecipientChange = (e) => setRecipient(e.target.value);
  const handleIcChange = (e) => setIc(e.target.value);
  const handleDicChange = (e) => setDic(e.target.value);
  const handleAddressChange = (e) => setAddress(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newRecipient = {
      recipient,
      ic,
      dic,
      address,
    };

    db.collection('recipients')
      .add(newRecipient)
      .then((docRef) => {
        console.log('Recipient added with ID:', docRef.id);
        // Reset form fields
        setRecipient('');
        setIc('');
        setDic('');
        setAddress('');
      })
      .catch((error) => {
        console.error('Error adding recipient:', error);
      });
  };

  const handleDelete = (recipientId) => {
    const confirmed = window.confirm('Opravdu chcete odstranit tohoto odběratele?');
    if (confirmed) {
      db.collection('recipients')
        .doc(recipientId)
        .delete()
        .then(() => {
          console.log('Recipient deleted with ID:', recipientId);
        })
        .catch((error) => {
          console.error('Error deleting recipient:', error);
        });
    }
  };  

  return (
    <>
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <FormGroup>
          <Input type="text" value={recipient} onChange={handleRecipientChange} placeholder="Odběratel" required />
        </FormGroup>
        <FormGroup>
          <Input type="text" value={ic} onChange={handleIcChange} placeholder="IČ" />
        </FormGroup>
        <FormGroup>
          <Input type="text" value={dic} onChange={handleDicChange} placeholder="DIČ" />
        </FormGroup>
      </FormRow>
      <FormGroup>
        <Textarea value={address} onChange={handleAddressChange} placeholder="Adresa" />
      </FormGroup>
      <Button type="submit">Přidat odběratele</Button>
    </Form>
    <Table>
  <TableHeader>
    <TableRow>
      <TableHeaderCell>Název</TableHeaderCell>
      <TableHeaderCell>IČ</TableHeaderCell>
      <TableHeaderCell>DIČ</TableHeaderCell>
      <TableHeaderCell>Adresa</TableHeaderCell>
      <TableHeaderCell style={{width:55}}>Akce</TableHeaderCell>
    </TableRow>
  </TableHeader>
  <tbody>
    {recipientsList.map((recipient) => (
      <TableRow key={recipient.id}>
        <TableCell>{recipient.recipient}</TableCell>
        <TableCell>{recipient.ic}</TableCell>
        <TableCell>{recipient.dic}</TableCell>
        <TableCell>{recipient.address}</TableCell>
        <TableCell>
          <Button onClick={() => handleDelete(recipient.id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </TableCell>
      </TableRow>
    ))}
  </tbody>
</Table>
  </>
  );
};

export default Recipients;