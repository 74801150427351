import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import Login from './Login';
import styled from 'styled-components';
import DownloadButtonPage from './DownloadButtonPage';

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
  }, []);

  if (!currentUser) {
    return <Login />;
  }

  return (
    <div>
      <DownloadButtonPage />
      </div>
  );
};

export default App;