import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Add faSignOutAlt for the logout icon
import { auth } from './firebase';

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-direction: row;
  width: 100%;
`;

const ProfileNav = styled.div`
  display: flex;
  align-items: center;
  margin-left:auto;
`;

const ProfileIcon = styled.div`
  background-color: #007f7f; /* Set the background color based on your theme */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:5px
`;

const ProfileText = styled.div`
  font-weight: 400;
  color: black;
  line-height: 1.1;
  text-align:right;
  margin-right:5px;
`;

const LogoutButton = styled.div`
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    opacity: 0.8; // Add some visual feedback on hover
  }
`;

const signOut = () => {
  auth.signOut().then(() => {
    console.log("User signed out");
  }).catch((error) => {
    console.error("Sign out error", error);
  });
};

function NavBar() {
  return (
    <Nav>
      <ProfileNav>
        <ProfileText>Richard<br /><strong>Mikeš</strong></ProfileText>
        <ProfileIcon>
          <FontAwesomeIcon icon={faUser} color="#fff" />
        </ProfileIcon>
        <ProfileIcon onClick={signOut}>
          <FontAwesomeIcon icon={faSignOutAlt} color="#fff" size="lg" title="Sign out" />
        </ProfileIcon>
      </ProfileNav>
    </Nav>
  );
}

export default NavBar;
