import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBKkGQ_7nDQD42vW1nbwPGq7GbH-yUuxV8",
    authDomain: "fakturix.firebaseapp.com",
    projectId: "fakturix",
    storageBucket: "fakturix.appspot.com",
    messagingSenderId: "184251928391",
    appId: "1:184251928391:web:57915fbfa3cf90e42e0662"
  };

  firebase.initializeApp(firebaseConfig);

  const db = firebase.firestore();
  const auth = firebase.auth();
  
  export { db, auth };