import React from 'react';
import { Page, Document, StyleSheet, Text, View, Font } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: 30,
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 10,
  },
  number: {
    fontSize: 14,
    marginBottom: 10,
  },
  tableContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000',
    borderRadius: 2,
    width: '100%'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderColor: '#000',
    padding: 10,
  },
  tableHeaderCell: {
    flex: 1,
    fontWeight: 'bold',
  },
  tableCell: {
    flex: 1,
  },
  classicRow: {
    flexDirection: 'row',
    width: '100%',
  },
  boldText: {
    fontWeight: 'bold',
  },
  totalSumRow: {
    flexDirection: 'row',
    borderBottomWidth: 0,
    borderTopWidth: 0.5,
    borderColor: '#000',
    padding: 10,
    fontWeight: 'bold',
  },
  bigLightText: {
    fontSize:18,
    fontWeight:600,
    marginTop: 25,
    color: 'lightgrey'
  },
  paticka: {
    marginTop:'auto',
    borderTop: 'solid black 1px',
    fontSize: 9,
  },
});

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
      fontWeight: 'light',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 'bold',
    },
  ],
});

const InvoicePDFEnglish = ({ invoice }) => {
  // Extract exchangeRate from the invoice data
  const exchangeRate = invoice.exchangeRate || 22.417; // Fallback to default if not provided
  const pdfFileName = `Faktura-${invoice.invoiceNumber}.pdf`;

  const formatCurrency = (amount, currency) => {
    // Convert amount to number and round to 2 decimal places
    const num = parseFloat(amount);

    if (isNaN(num)) {
      return 'Invalid Price';
    }

    // Formatting based on currency
    const currencySymbol = currency.toLowerCase() === 'czk' ? 'Kč' : currency;
    return num.toLocaleString('cs-CZ', { minimumFractionDigits: 0 }) + ` ${currencySymbol}`;
  };

  const calculateTotalSum = () => {
    return invoice.items.reduce((sum, item) => {
      const price = parseFloat(item.finalPrice.replace(/\s+/g, '')); // Convert to number
      return sum + price;
    }, 0);
  };

  const formatDate = (dateString) => {
    // Convert date string from YYYY-MM-DD to DD. MM. YYYY
    const [year, month, day] = dateString.split('-');
    return `${day}. ${month}. ${year}`;
  };

  const calculateValidityDate = () => {
    const invoiceDate = new Date(invoice.invoiceDate);
    const validityDays = parseInt(invoice.validityDays, 10);
    const validityDate = new Date(invoiceDate.getTime() + validityDays * 24 * 60 * 60 * 1000);
    return formatDate(validityDate.toISOString().slice(0, 10));
  };

  return (
    <Document title={pdfFileName} fileName={pdfFileName}>
      <Page size="A4" style={styles.page}>
        <Text>Invoice</Text>
        <Text style={styles.boldText}>{`${invoice.invoiceNumber}`}</Text>
        <Text style={{ marginBottom: 10 }}>{formatDate(invoice.invoiceDate)}</Text>
        <View style={styles.classicRow}>
          <View style={[styles.tableContainer, { marginRight: 10 }]}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell}>
                <Text style={styles.boldText}></Text>
                <Text style={{ marginBottom: 10, fontWeight: 'bold' }}>Richard Mikeš</Text>
                <Text>Vrchlického 1986</Text>
                <Text>272 01 Kladno</Text>
                <Text style={{ marginBottom: 10 }}>Czech Republic</Text>
                <Text style={{ marginBottom: 10 }}>CIN: 09634975</Text>
                <Text>Email: webdesign@richardmikes.cz</Text>
                <Text>Phone number: +420605781725</Text>
                <Text>Website: www.richardmikes.cz</Text>
              </View>
            </View>
            <View style={[styles.tableRow, { borderBottomWidth: 0 }]}>
              <View style={styles.tableCell}>
                <Text>Monetary institution: Česká spořitelna</Text>
                <Text>Account number (IBAN): CZ2308000000004375843053</Text>
                <Text>SWIFT: GIBACZPX</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableContainer}>
            <View style={[styles.tableRow, { borderBottomWidth: 0 }]}>
              <View style={styles.tableCell}>
                <Text style={{ fontWeight: 'bold' }}>RECIPIENT</Text>
                <Text style={{ marginBottom: 10, fontWeight: 'bold' }}>{invoice.recipient}</Text>
                {invoice.address.split('\n').map((line, index) => (
                  <Text key={index} style={{ marginBottom: 0 }}>
                    {line}
                  </Text>
                ))}
                {invoice.ic && <Text style={{ marginTop: 10 }}>{`CIN: ${invoice.ic}`}</Text>}
              {invoice.dic && <Text>{`VATIN: ${invoice.dic}`}</Text>}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={[styles.tableRow, { borderBottomWidth: 0 }]}>
            <View style={[styles.tableCell, { maxWidth: 80 }]}>
              <Text>Date of Issue:</Text>
              <Text>{formatDate(invoice.invoiceDate)}</Text>
            </View>
            <View style={[styles.tableCell, { maxWidth: 80 }]}>
              <Text>Due date:</Text>
              <Text style={styles.boldText}>{calculateValidityDate()}</Text>
            </View>
            <View style={[styles.tableCell, { maxWidth: 200 }]}>
              <Text>VARIABLE SYMBOL:</Text>
              <Text style={styles.boldText}>{invoice.invoiceNumber}</Text>
            </View>
            <View style={[styles.tableCell, { maxWidth: 200 }]}>
              <Text>CONSTANT SYMBOL:</Text>
              <Text>0308</Text>
            </View>
            <View style={styles.tableCell}>
              <Text>Form of payment:</Text>
              <Text style={styles.boldText}>Bank transfer</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableContainer}>
        <View style={styles.tableRow}>
          <Text style={styles.tableHeaderCell}>Name</Text>
          <Text style={styles.tableHeaderCell}>Amount</Text>
        </View>
        {invoice.items &&
            invoice.items.map((item, index) => (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCell}>{item.title}</Text>
                <Text style={styles.tableCell}>
                  {formatCurrency(item.finalPrice, 'USD')} / {formatCurrency((item.finalPrice * exchangeRate).toFixed(2), 'CZK')}
                </Text>
              </View>
            ))}
          <View style={styles.totalSumRow}>
            <Text style={styles.tableCell}>In total</Text>
            <Text style={styles.tableCell}>
              {formatCurrency(calculateTotalSum(), 'USD')} / {formatCurrency((calculateTotalSum() * exchangeRate).toFixed(2), 'CZK')}
            </Text>
          </View>
        </View>
        <View><Text style={styles.bigLightText}>We are not VAT payers</Text></View>
        <View style={styles.paticka}>
          <Text>Richard Mikeš</Text>
          <Text>Bank connection: Česká spořitelna, IBAN CZ2308000000004375843053</Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDFEnglish;
